<template>
	<main class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>MY 서비스</li>
							<li>초대하기 관리</li>
						</ul>
					</div>
					<h2 class="page_title">초대하기 관리</h2>
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<div class="account_info">
						<template v-if="!isLogin()"><b>로그인이 필요한 서비스입니다</b></template>
						<template v-else><b>{{ getSessionProc().emailId }}</b> 님께서 신청한 서비스 이용 정보입니다.</template>
					</div>
					<div class="tbl_board">
						<table>
							<thead>
							<tr>
								<th><input type="checkbox" id="cssCheckbox1" v-model="chkAll" @click="allChkIdxs" class="inp_check"> No.</th>
								<th>E-mail</th>
								<th>이름</th>
								<th>등록일</th>
							</tr>
							</thead>
							<tbody>
							<!-- 리스트 없을경우-->
							<template v-if="!isLogin() || inviteList.length<1">
								<tr>
									<td colspan="7" class="content_none">등록된 컨텐츠가 없습니다.</td>
								</tr>
							</template>
							<!-- 리스트 있을경우-->
							<template v-else>
							<tr v-for="(item, index) in inviteList"  v-bind:key="'chkBox-'+index">
								<td data-td="No">
									<input type="checkbox" v-model="chkIdxs" :id="'chkBox' + item.idx"
								                        :value="item.idx" class="inp_check">
									<label :for="'chkBox' + item.idx">{{ item.idx }}</label>
								</td>
								<td data-td="E-mail" @click="goView(item.idx)"><a href="#">{{ item.guest }}</a></td>
								<td data-td="이름">{{ item.guestName }}</td>
								<td data-td="등록일">{{ item.createDate | dateFilter }}</td>
							</tr>
							</template>
							</tbody>
						</table>
					</div>

					<div class="button_area align_right">
						<button type="button" class="btn_add btn_primary" v-if="isLogin()" @click="goForm">추가하기</button>
						<button type="button" class="btn_delete btn_black" v-if="isLogin() && inviteList.length>0" @click="doDelete">삭제하기</button>
					</div>

					<div class="pagination">
						<a href="#" class="prev"><span class="blind">이전 페이지</span></a>
						<a v-for="(item,index) in totalPage"  :key="'t1-'+index" href="#" @click="goPage(item-1)" :class="{ 'current': item == (page +1) }">{{item}}</a>
						<a href="#" class="next"><span class="blind">다음 페이지</span></a>
					</div>

				</div>
			</div>

		</div>
	</main>
</template>

<script>
export default {
	name: "inviteList",
	data() {
		return {
			inviteList: [],
			page: 0,
			count: 0,
			size: 8,

			totalPage: 0,
			totalCount: 0,

			chkAll: false,
			chkIdxs: [],
		}
	},
	mounted() {
		this.getMyInviteList()
	},
	methods: {

		doDelete() {

			this.$eventBus.$emit("startProgress")

			this.axiosCaller.post(this, this.APIs.MY_SERVICE + "/deleteInviteList", { 'idxs' : this.chkIdxs.toString() }, (res) => {
				var result = res.data

				if (result.status === "ok") {

					this.swalUtils.gritter("삭제되었습니다.", result.msg, "success", 800).then(() => {
						location.href="/myService/inviteList"
					});

				} else {
					this.swalUtils.gritter("Error", result.msg, "error", 800)
				}

				this.$eventBus.$emit("doneProgress")
			})

		},

		allChkIdxs: function () {
			const self = this;
			if (this.chkAll) {
				this.chkIdxs = []
			} else {
				this.chkIdxs = []
				this.inviteList.forEach(function (value) {
					self.chkIdxs.push(value.idx)
				})
			}
		},

		goView(_idx) {
			location.href='/myService/inviteView?idx='+_idx
		},

		goForm() {
			location.href='/myService/inviteView'
		},

		getMyInviteList() {

			const self = this;

			if( this.isLogin() ) {

				this.$eventBus.$emit("startProgress")

				var temp = JSON.parse(JSON.stringify(this.$route.params))
				temp.size = this.size || 8
				temp.page = this.page || 0
				temp.emailId = this.getSessionProc().emailId;

				this.axiosCaller.get(this, this.APIs.MY_SERVICE + "/getMyInviteList", temp, (res) => {
					var result = res.data
					console.log('::::::result', result)
					if (result.status === "ok") {
						self.inviteList = result.list.content;
						self.totalCount = result.list.totalElements;
						self.totalPage = result.list.totalPages;
					} else {
						this.swalUtils.gritter("Error", result.msg, "error", 800)
					}
					this.$eventBus.$emit("doneProgress")
				})

			}

		},

		goPage(page) {

			this.page = page;
			this.getMyInviteList();
		}

	}
}
</script>

<style scoped>

</style>